<template lang="pug">
data-page
  template(#aside)
    api-based-tree(
      v-model="queryForm.checkOrgId",
      :load-method="treeLoadMethod",
      auto-select
    )

  template(#query)
    query-form(v-model="queryForm", :fields="queryFormFields", @query="query")

  Table(:loading="loading", :data="items", :columns="tableColumns")

  data-pagination(:pagination="pagination", @paginate="query")
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      items: [],
      queryForm: {
        checkOrgId: "",
      },
      pagination: {
        total: 0,
        pageNumber: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    queryFormFields() {
      return [
        {
          key: "personName",
          label: "用户姓名",
        },
        {
          key: "type",
          label: "体检类别",
          as: "select",
          loadMethod: () => this.$api.getDictAsOptions("checkType"),
        },
        {
          key: "package",
          label: "体检套餐",
          as: "select",
          loadMethod: () => this.$api.getDictAsOptions("checkType"),
        },
        {
          key: "date",
          label: "体检日期",
          as: "date",
          default: new Date(),
        },
      ];
    },

    tableColumns() {
      return [
        {
          type: "index",
        },
        {
          key: "personName",
          title: "用户姓名",
          render: (h, { row }) => h("span", row.tgroupPerson?.personName),
        },
        {
          key: "sex",
          title: "性别",
          render: (h, { row }) => h("span", row.tgroupPerson?.sex),
        },
        {
          key: "personName",
          title: "体检类别",
        },
        {
          key: "personName",
          title: "体检套餐",
        },
        {
          key: "orderDate",
          title: "体检日期",
          render: (h, { row }) =>
            h("entity-field-date", {
              props: {
                value: row.createTime,
                format: "YYYY-MM-DD",
              },
            }),
        },
        {
          key: "idCard",
          title: "身份证号",
          width: 160,
          render: (h, { row }) =>
            h("span", this.$utils.formatIDCard(row.tgroupPerson?.idCard)),
        },
        {
          key: "mobile",
          title: "联系方式",
          width: 130,
          render: (h, { row }) => h("span", row.tgroupPerson?.mobile),
        },
        {
          key: "createTime",
          title: "预约日期",
          render: (h, { row }) =>
            h("entity-field-date", {
              props: {
                value: row.createTime,
                format: "YYYY-MM-DD",
              },
            }),
        },
      ];
    },
  },
  watch: {
    "queryForm.checkOrgId": {
      handler() {
        this.query();
      },
    },
  },
  methods: {
    async query(q) {
      if (this.loading) return;

      this.loading = true;
      try {
        const queryParams = Object.assign(
          {},
          this.queryForm,
          this.pagination,
          q
        );
        const res = await this.$api.healthy.tOrderRecord.query(queryParams);

        if (res?.success) {
          const {
            pages: pageNumber,
            size: pageSize,
            total,
            records: items,
          } = res.data;

          Object.assign(this, {
            pagination: {
              total,
              pageSize,
              pageNumber,
            },
            items,
          });
        } else {
          this.$Message.error(res.message);
        }
      } catch (err) {
        this.$Message.error(err.message);
      }

      this.loading = false;
    },

    async treeLoadMethod() {
      try {
        const res = await this.$api.healthy.tCheckOrg.getAllCheckOrg();

        if (res?.success) {
          return (res.data ?? []).map((i) => ({
            title: i.name,
            value: i.id,
            _raw: i,
          }));
        } else {
          return [];
        }
      } catch (err) {
        this.$Message.error(error.message);
      }
    },

    handleEntityActions() {},
  },
};
</script>

<style lang="less" scoped></style>
