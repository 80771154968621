var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "data-page",
    {
      scopedSlots: _vm._u([
        {
          key: "aside",
          fn: function () {
            return [
              _c("api-based-tree", {
                attrs: { "load-method": _vm.treeLoadMethod, "auto-select": "" },
                model: {
                  value: _vm.queryForm.checkOrgId,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryForm, "checkOrgId", $$v)
                  },
                  expression: "queryForm.checkOrgId",
                },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "query",
          fn: function () {
            return [
              _c("query-form", {
                attrs: { fields: _vm.queryFormFields },
                on: { query: _vm.query },
                model: {
                  value: _vm.queryForm,
                  callback: function ($$v) {
                    _vm.queryForm = $$v
                  },
                  expression: "queryForm",
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("Table", {
        attrs: {
          loading: _vm.loading,
          data: _vm.items,
          columns: _vm.tableColumns,
        },
      }),
      _c("data-pagination", {
        attrs: { pagination: _vm.pagination },
        on: { paginate: _vm.query },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }